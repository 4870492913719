<template>
    <div class="widget-area">
        <div class="first front-widgets">
            <aside id="recent-posts-4" class="widget">
                <h3 class="widget-title">
                    <a href="/novidades" id="novidades-titulo" title="Novidades">
                        Novidades				
                    </a>
                </h3>
                <ul>
                    <div
                        v-if="isLoading"
                        class="loading"
                    >
                        <ContentLoading 
                            width="100%"
                            height="60px"
                            animation-duration="2s"
                        />
                        <ContentLoading 
                            width="100%"
                            height="60px"
                            animation-duration="2.2s"
                        />
                        <ContentLoading 
                            width="100%"
                            height="60px"
                            animation-duration="2.4s"
                        />
                    </div>

                    <div v-else v-for="(novidade, index) in novidadesLista">
                        <Transition :name=animacao>
                            <li v-if="index <= ultimoAberto && index >= primeiroAberto">
                                <RouterLink :to="`/novidades/${novidade.slug}`">
                                    <span class="post-date">{{onlyData(novidade.data)}}</span>
                                    {{novidade.titulo}}
                                </RouterLink>
                            </li>
                        </Transition>
                    </div>
                </ul>
            </aside>
        </div>
        <div class="navigation ">
            <a :onClick="anterior" id="anteriores" :class="{'desactive': primeiroAberto === 0}" rel="0">Anteriores</a>
            &nbsp;&nbsp;&nbsp;
            <a :onclick="proximo" id="proximas" :class="{'desactive': ultimoAberto === 14}" rel="4">Próximas</a>
        </div>
    </div>
    <div class="capa-gourmet">
        <a href="https://www.bourbonshopping.com.br/novidades/canal-do-spotify-do-bourbon-shopping-tem-selecao-de-musica-boa-para-todas-as-horas/">                        
            <img src="../assets/img/playlist-spotify.png" width="484" alt="banner spotify">
        </a>                    
    </div>
    <div class="clearfix"></div>
</template>

<script>
    import { RouterLink } from 'vue-router';
    import { novidades } from '../service/Novidades';
    import ContentLoading from './ContentLoading.vue';

    export default {
    name: "NovidadesHome",
    mixins: [novidades],
    components: { ContentLoading, RouterLink },
    data() {
        return {
            primeiroAberto: 0,
            ultimoAberto: 2,
            animacao: "subir"
        };
    },
    beforeMount() {
        this.getNovidadesCapaGeral();
    },
    methods: {
        onlyData(data) {
            return data.split(" ")[0];
        },
        proximo() {
            this.animacao = "subir";
            if (this.ultimoAberto === 14) {
                return;
            }
            this.primeiroAberto++;
            this.ultimoAberto++;
        },
        anterior() {
            this.animacao = "descer";
            if (this.primeiroAberto === 0) {
                return;
            }
            this.primeiroAberto--;
            this.ultimoAberto--;
        }
    },
}
</script>

<style>
    .subir-enter-from, .descer-leave-to {
        margin-bottom: -65px;
        opacity: 1;
    }

    .subir-enter-to, .descer-leave-from {
        margin-top: 0;
        opacity: 0;
    }
    
    .subir-enter-active,
    .subir-leave-active, 
    .descer-enter-active, 
    .descer-leave-active {
        transition: 1s;
    }

    .subir-leave-from, .descer-enter-to { 
        margin-top: 0;
        opacity: 1;
    }

    .subir-leave-to, .descer-enter-from {
        margin-top: -65px;
        opacity: 0;
    }

    .loading {
        display: flex;
        flex-direction: column;
        gap: 5px
    }

    .widget-area {
        float: left;
        width: auto;
        max-width: 430px;
        margin: 20px 0 0 45px;
        word-wrap: break-word;
    }

    .widget-area .widget {
        margin-bottom: 0;
    }

    .widget-area .widget h3 {
        color: #FFF;
        font-size: 11px;
        font-style: italic;
        line-height: 2.18;
        font-weight: 700;
        text-transform: uppercase;
    }

    #novidades-titulo {
        color: black;
    }

    .widget-area .widget a {
        font-size: 12px;
        color: #fff;
        text-decoration: none;
    }

    .widget-area ul {
        height: 190px;
        overflow: hidden;
    }

    .widget-area ul li {
        font-size: 11px;
        background: #1F2323;
        cursor: pointer;
        height: 60px;
        line-height: 60px;
        padding: 0 14px;
        margin-bottom: 5px;
        -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=85)!important;
        filter: alpha(opacity=85)!important;
        -moz-opacity: .85 !important;
        -khtml-opacity: .85 !important;
        opacity: .85 !important;
        overflow: hidden;
    }

    .widget-area .post-date {
        color: #fff;
        font-size: 12px;
        margin-right: 7px;
        -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
        filter: alpha(opacity=80);
        -moz-opacity: .8;
        -khtml-opacity: .8;
        opacity: .8;
    }

    .navigation {
        margin-bottom: 50px;
        margin-top: 15px;
        padding-left: 3px;
        font-size: 11px;
        color: #fff;
        font-family: 'Novecento Bold', Arial, Helvetica, sans-serif !important;
        text-transform: uppercase;
        font-style: italic;
    }

    .navigation a {
        color: #fff;
        text-decoration: none;
    }

    .navigation a.desactive {
        -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=70)!important;
        filter: alpha(opacity=70)!important;
        -moz-opacity: .7!important;
        -khtml-opacity: .7!important;
        opacity: .7!important;
        cursor: default;
    }

    .capa-gourmet {
        width: 484px;
        display: block;
        float: right;
        margin-right: 20px;
        text-decoration: none !important;
    }

    #anteriores, #proximas {
        color: black;
        cursor: pointer;
    }

    @media (max-width: 992px) {
        .subir-leave-from, .descer-enter-to { 
            margin-top: 0;
            opacity: 1;
        }

        .subir-leave-to, .descer-enter-from {
            margin-top: -40px;
            opacity: 0;
        }

        .subir-enter-from, .descer-leave-to {
            margin-bottom: -40px;
            opacity: 1;
        }

        .subir-enter-to, .descer-leave-from {
            margin-top: 0;
            opacity: 0;
        }

        .widget-area {
            float: none;
            margin: 0 auto;
        }

        .widget-area .widget a { 
            font-size: 11px;
        }

        .widget-area .widget h3 {
            font-size: 18px;
            font-style: initial;
            margin: 0 auto;
            text-align: center;
        }

        .widget-area ul {
            height: 115px;
        }

        .widget-area ul li {
            height: 35px;
            background: #86447b;
            line-height: 36px;
        }

        .widget-area .post-date {
            color: #b8b8b8 !important;
            margin-right: 3px !important;
        }

        .navigation {
            text-align: center;
            margin-top: 25px;
            margin-bottom: 50px;
        }

        .navigation a {
            font-size: 12px;
            font-style: initial;
        }

        .capa-gourmet {
            display: none;
        }
    }    
</style>